<template>
    <div>
        <div>
            <div class="title">修改头像</div>
            <div class="file_img">
                <input type="file" id="aimg" @change="changeImg">
                <img v-if="all.listObj&&all.listObj.user_avatar" class="imgs" :src=" 'http://82.157.140.72/test' + (all.listObj&&all.listObj.user_avatar)" alt="">
            </div>
        </div>
        <div class="top">
            <div class="span">昵称:<span class="tit">{{ all.listObj.user_nick_name}}</span></div>
            <div class="span">心情:<span class="tit">{{ all.listObj.user_trends }}</span></div>
            <div class="span">手机:<span class="tit">{{ all.listObj.user_phone }}</span></div>       
            <div class="span">生日:<span class="tit">{{ all.listObj.user_birthday }}</span></div>
            <div class="span">地址:<span class="tit">{{ all.listObj.user_birthday_city }}</span></div>
            <div class="span">地区:<span class="tit">{{ all.listObj.user_address }}</span></div>
            <div class="span">真实姓名:<span class="tit">{{ all.listObj.user_real_name }}</span></div>
            <div class="span">个人介绍:<span class="tit">{{ all.listObj.user_profile }}</span></div>
        </div>
    </div>
</template>

<script>
import api from '../../../api/user'
export default {
    name: 'index',
    components: {

    },
    props: {
        usersList: {
            type: Object,
            default: () => {
                return {
                    listObj:{
                      
                    }
                }
            }
        },
    },

    data() {
        return {
            listObj:{
               
            }

        }
    },
    computed: {
        all() {
            let Obj = this.usersList
   
            if (!Obj.listObj) {
                Obj.listObj = this.listObj
            }
            return Obj
        }
    },

    created() {

    },
    mounted() {


    },
    methods: {
        // 修改 img
        changeImg() {
            var imgDom = document.getElementById("aimg");
            var file = imgDom.files[0];
            var data = new FormData();
            data.append("images", file);
            api.upload(data).then((res) => {
                if(res.data.code ==200){
                    this.upImg(res.data.result.filePath)  
                }
            })

        },
        upImg(img){
            var obj ={
                user_avatar:img
            }
            api.userPatch(obj).then((res) => {
                if(res.data.code ==200){
                    this.$emit("filesData",res)
                }
            })
        }
     


    },
}
</script>

<style scoped>
.title{
    margin-top: 1px;
    margin-bottom: 10px;
    font-size: 14px;
}
.span {
    margin-top: 3px;
    font-size: 12px;
}

.tit {
    font-size: 10px;
    color: #999;
}

span {
    font-size: 11px;
}

.top {
    margin-top: 12px;
}

.file_img {
    width: 190px;
    height: 190px;
    border: 1px solid rgb(216 216 216 / 95%);
    border-radius: 4px;
    background: rgb(0 0 0 / 7%);
    position: relative;
    margin-top: 8px;
}

.file_img::before {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    opacity: 0;
    background: url('../../../assets/updataimg.png') no-repeat;
    background-size: 100% 100%;
    transition: all .7s ease-out;
}

.file_img:hover.file_img::before {
    opacity: 1;
    transition: all .7s ease-out;
}

#aimg {
    width: 190px;
    height: 190px;
    border: 1px solid rgba(255, 255, 255, .7);
    border-radius: 4px;
    background: red;
    opacity: 0;
    z-index: 3;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.imgs {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 4px;
    overflow: hidden;
}
</style>