/**
 * 引入 axios  拦截器
 */
import apiService from '../utils/service'
import {token} from '../components/js/index'
/**
 *  接口 api
 */
export default {
    user(data) {
        return apiService({
            url: '/user',
            method: 'get',
            headers: {
                // authorization: ` Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`
                authorization: ` Bearer ${token.token}`
            },
            params: data
        })
    },
    userPatch(data) {
        return apiService({
            url: '/user',
            method: 'PATCH',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },
    upload(data) {
        return apiService({
            url: '/base/upload',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },
    
    loginRecord(data) {
        return apiService({
            url: '/user/loginRecord',
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            params: data
        })
    },

}

