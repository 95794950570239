<template>
	<div class="box">
		<left :left="left">
			<usersList :usersList="usersList" @filesData="filesData"></usersList>
		</left>

		<div class="ul_all">
			<div class="li_all" @click="zindex=0" :class="zindex==0 ? 'bf' :''">个人信息</div>
			<div class="li_all" @click="zindex=1" :class="zindex==1 ? 'bf' :''">登录记录</div>
		</div>

		<div class="ul" v-if="zindex==0">
			<div class="sul">
				<div class="sli">
					<h5>昵称</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userNickName" type="text">
					</div>
				</div>
				<div class="sli">
					<h5>签名</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userTrends" type="text">
					</div>
				</div>
			</div>
			<div class="sul">
				<div class="sli">
					<h5>出生地</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userBirthArea" type="text">
					</div>
				</div>
				<div class="sli">
					<h5>具体地址</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userAddress" type="text">
					</div>
				</div>
			</div>
			<div class="sul">
				<div class="sli">
					<h5>身份证</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userIdCardNo" type="number">
					</div>
				</div>
				<div class="sli">
					<h5>真实姓名</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userRealName" type="text">
					</div>
				</div>
			</div>
			<div class="sul">
				<div class="sli">
					<h5>生日</h5>
					<div class="lis"  @click="calendar.isHide=true">
						<div class="add_album_pt" type="text" >{{userBirthDayVal}}</div>
					</div>
					<cutTime :calendar="calendar" @userBirthDay="userBirthDay"></cutTime>
				</div>
			</div>
			<div class="sul">
				<div class="sli">
					<h5>个人介绍</h5>
					<div class="lis">
						<input class="add_album_pt" v-model="userProfile" type="text">
					</div>
				</div>
			</div>
			<div class="btn">
				<div @click="btn()">确定修改</div>	
			</div>		
		</div>

		<div v-if="zindex==1" class="ul">
			<div>
				<div v-for="item in listRecord">	
					<!-- <span>{{timeDayun(item.createdAt)}}-</span> -->
					<span>{{item.login_cip_desensitization}}-</span>
					<span>{{item.login_cname}}-</span>
					<span>{{item.login_type}}-</span>
					<span>{{item.login_cid}}-</span>
					<span>{{timeDayun(item.updatedAt)}}</span>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import left from '../../components/common/index/left.vue'
import usersList from './components/user.vue'
import cutTime from './components/cutTime.vue'
import api from '../../api/user'
import {timeDay} from '../../components/js/index'
export default {
	name: 'index',
	components: {
		left,
		usersList,
		cutTime
	},

	data() {
		return {
			zindex:0,
			left:{
				isbg:"#fff"
			},
			usersList:{
				listObj:{},
			},
			userNickName:"",  // 昵称
			userTrends:'',	//描述
			userBirthDayVal:"",  // 生日
			userBirthArea:"",  // 地区
			userAddress:"",  // 地址
			userIdCardNo:"",  // 身份证号
			userRealName:"",  // 真实姓名
			userProfile:"",  // 个人介绍
			calendar:{
				isHide:false,
			},
			listRecord:'',
		}
	},

	created() {

	},
	mounted() {
		this.userFun()
	},
	methods: {
		userFun(){
			api.user().then((res) => {
				this.usersList.listObj =res.data.result
				this.userNickName=res.data.result.user_nick_name
				this.userBirthDayVal=res.data.result.user_birthday
				this.userBirthArea=res.data.result.user_birthday_city
				this.userAddress=res.data.result.user_address
				this.userRealName=res.data.result.user_real_name
				this.userProfile=res.data.result.user_profile
				this.userTrends=res.data.result.user_trends
				this.userIdCardNo=res.data.result.user_id_card_no
			})

			api.loginRecord().then((res) => {
				if(res.data.code ==200){
					console.log(res,3);
					this.listRecord = res.data.result

				}
			})
		},
		timeDayun(v){
			return timeDay(v)
		},
		btn(){
			var obj ={
				user_nick_name:this.userNickName,
				user_trends: this.userTrends, 
				user_birthday: this.userBirthDayVal , 
				user_birthday_city: this.userBirthArea , 
				user_address: this.userAddress, 
				user_auth: "N", 
				user_id_card_no: this.userIdCardNo, 
				user_real_name: this.userRealName, 
				user_profile: this. userProfile
			}
			api.userPatch(obj).then((res) => {
				if(res.data.code ==200){
					this.userFun()
				}
			})
		},
		filesData(val){
			this.userFun()
		},
	
		userBirthDay(time){
			this.userBirthDayVal =time
		},
		
	},
}
</script>

<style scoped>
.box {
	margin-left: 213px;
	margin-top: 60px;
	overflow: hidden;
}
.ul{
	width:1000px;
	margin-top: 30px;
	margin-left: 40px;
}
.lis{
	width: 100%;
	height: 34px;
	border: 1px solid rgb(230, 221, 221);
	border-radius: 4px;
	margin-top: 6px;
}
.lis input{
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 4px;
	padding-left: 12px;
	box-sizing: border-box;
}
h5{
	font-size: 12px;
}
.sul{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2px;
}
.sli{
	flex: 1;
	margin: 10px;
}
.btn{
	width: 980px;
	height: 34px;
	margin: 50px  auto;
	text-align: center;
	line-height: 34px;
	border-radius: 4px;
	background: red;
	color: #fff;
	cursor: pointer;
}
.cut_time1{
	width: 600px;
	height: 500px;
	background: red;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50% ,-50%);
	margin-left: 106px;
}
.add_album_pt{
	line-height: 34px;
	padding-left: 12px;
	box-sizing: border-box;
}


.ul_all{
	display: flex;
	margin-top: 100px;
	margin-left: 40px;
	cursor: pointer;
}
.li_all{
	margin-right: 12px;
	font-size: 14px;
	line-height: 32px;
}
.bf{
	border-bottom: 4px solid red;
    color: red;
}
</style>