<template>
  <div v-if='all.isHide'>
    <div class="data_box"></div>
    <div class="calendar_box">
      <div class="title">
        <div class="title_name">
          <span class="left" @click="all.isHide = false">取消</span>
        </div>
        <div class="title_name">
          <span class="modele">选择日期</span>
        </div>
        <div class="title_name">
          <!-- <span class="right" @click="all.isHide = false">确认</span> -->
        </div>
      </div>

      <div class="calendar">
        <div class="month">
          <img class="img1" @click="nian(1)" src="../../../assets/no.png" alt="">
          <div>
            <div class="arrow left_one" @click="pickPre(currentYear, currentMonth)">
              <img src="../../../assets/no.png" alt="">
            </div>
            <div class="year-month">
              <span>{{ currentYear }}</span>
              <span>-</span>
              <span>{{ currentMonth }}</span>
            </div>
            <div class="arrow right_two" @click="pickNext(currentYear, currentMonth)">
              <img src="../../../assets/no.png" alt="">
            </div>
          </div>
          <img class="img2" @click="nian(2)" src="../../../assets/no.png" alt="">
        </div>

        <div class="weekdays">
          <div>一</div>
          <div>二</div>
          <div>三</div>
          <div>四</div>
          <div>五</div>
          <div>六</div>
          <div>日</div>
        </div>

        <div class="days">
          <!-- 核心 v-for循环 每一次循环用<>标签创建一天 day(dayobject) -->
          <div v-for="(dayobject, index) in days" :key="index" @click="day(dayobject); tabIndex = index"
            :class="tabIndex == index ? 'bf' : ''">
            <!--本月-->
            <!--如果不是本月  改变类名加灰色-->
            <span v-if="dayobject.day.getMonth() + 1 != currentMonth" class="other-month">
              {{ dayobject.day.getDate() }}
            </span>
            <!--如果是本月  还需要判断是不是这一天-->
            <span v-else>
              <!--今天  同年同月同日-->
              <span
                v-if="dayobject.day.getFullYear() == new Date().getFullYear() && dayobject.day.getMonth() == new Date().getMonth() && dayobject.day.getDate() == new Date().getDate()"
                class="active">{{ dayobject.day.getDate() }}
              </span>
              <span v-else>{{ dayobject.day.getDate() }}</span>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'calendar',
  props: {
    calendar: {
      type: Object,
      default: () => {
        return {
          isHide: false,           // 显隐
          callbackbtnOn: () => { },    // 关闭 回调
          newDate: "", // 预留 时间
        }
      }
    }
  },

  data() {
    return {
      tabIndex: 0,
      isHide: false,
      newDate: new Date(),
      currentDay: 1,
      currentMonth: 1,
      currentYear: 1993,
      currentWeek: 1,
      days: [],
    }
  },
  components: {

  },
  computed: {
    all() {
      let Obj = this.calendar
      if (!Obj.newDate) {
        Obj.newDate = this.newDate
      }
      if (!Obj.isHide) {
        Obj.isHide = this.isHide
      }
      if (!Obj.callbackbtnOn) {
        Obj.callbackbtnOn = this.callbackbtnOn
      }
      return Obj
    }
  },
  created() {
    //在vue初始化时调用
    this.initData(this.newDate);
  },
  methods: {
    initData(cur) {
      var date;
      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var d = new Date(this.formatDate(now.getFullYear(), now.getMonth(), 1));
        d.setDate(35);
        date = new Date(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }
      this.currentDay = date.getDate();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;

      this.currentWeek = date.getDay(); // 1...6,0
      if (this.currentWeek == 0) {
        this.currentWeek = 7;
      }
      var str = this.formatDate(this.currentYear, this.currentMonth, this.currentDay);
      this.days.length = 0;
      // 今天是周日，放在第一行第7个位置，前面6个
      for (var i = this.currentWeek - 1; i >= 0; i--) {
        var d = new Date(str);
        d.setDate(d.getDate() - i);
        var dayobject = {};
        dayobject.day = d;
        this.days.push(dayobject); //将日期放入data 中的days数组 
      }
      //其他周
      for (var i = 1; i <= 35 - this.currentWeek; i++) {
        var d = new Date(str);
        d.setDate(d.getDate() + i);
        var dayobject = {};
        dayobject.day = d;
        this.days.push(dayobject);
      }

    },
    pickPre(year, month) {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 切换月
    pickNext(year, month) {
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(35);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 切换年
    nian(v) {
      if (v == 1) {
        this.currentYear--
      } else {
        this.currentYear++
      }
      this.initData(new Date(this.currentYear--, new Date().getMonth(), new Date().getDate()));
      // console.log(new Date(this.currentYear--, new Date().getMonth(), new Date().getDate()),222);
    },

    // 点击 选择日期方法
    day(val) {
      this.handleSelect(val)
      // console.log(val,"1111111");
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },

    // 时间处理
    handleSelect(e) {
      let date = []
      for (const i in e) {
        date.push(this.gettime(e[i]))
      }
    },
    // 时间处理 处理格式
    gettime(data) {
      let value = data.getFullYear() + '-' +
        this.checkTime(data.getMonth() + 1) + '-' +
        this.checkTime(data.getDate())
      //  + ' ' +
      // this.checkTime(data.getHours()) + ':' +
      // this.checkTime(data.getMinutes()) + ':' +
      // this.checkTime(data.getSeconds());

      this.$emit('userBirthDay' , value)
      console.log(value, ' 时间val');
      this.all.isHide =false
      return value
    },

    // 时间处理 如果时间小于10 ，则再前面加一个'0'
    checkTime(i) {
      if (i < 10) {
        i = "0" + i
      }
      return i;
    },
  },
}
</script>


<style scoped lang="scss">
.data_box {
  width: 100%;
  height: 100%;
  position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50% ,-50%);
	margin-left: 106px;
  background: rgba(0,0, 0, 0.2);

}

.calendar_box {
  width: 600px;
  min-height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 106px;
  border: 1px solid #090202;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.title {
  width: 100%;
  height: 60px;
  background: #f8f9fb;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.title_name {
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #2e2f33;

}

.left {
  float: left;
  padding-left: 12px;
  color: #999;
  cursor: pointer;
}

.right {
  float: right;
  padding-right: 12px;
  color: #28D9D9;
  cursor: pointer;
}

.right>img {
  width: 30px;
}

.calendar {
  width: 100%;
  margin: 0 auto;
}

.month {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
}

.month>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img1 {
  position: absolute;
  left: 12px;
  top: 12px;
}

.img2 {
  position: absolute;
  right: 12px;
  top: 12px;
}

.month>div>div {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 12px;
}

.left_one {
  margin-right: 10px;
}

.right_two {
  margin-left: 10px;
}

.weekdays {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: 50px;
}

.weekdays>div {
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.days {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.days>div {
  display: flex;
  width: 80px;
  font-size: 14px;
  color: #000;
  height: 70px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.days>div .active {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
}

.days>div .other-month {
  color: #ddd;
}


.days .bf {
  background: #fa7199;
  border-radius: 12px;
}
</style>
